import React, { useEffect, useState } from "react";
// https://www.pubnub.com/docs/sdks/javascript/react
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';

import "./Chat.css";

function Chat(props) {
  const pubnub = usePubNub();
  const [channels, setChannels] = useState([]);
  const [messages, addMessage] = useState([]);
  const [historyMessages, setHistoryMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [fetchMessageOptions, setMetchMessageOptions] = useState({});

  const handleMessage = event => {
    const message = event.message;
    if (typeof message === 'string' || message.hasOwnProperty('update')) {
      const text = message.update || message;
      addMessage(messages => [...messages, text]);
    }
  };
    
    const handleMessageAction = ma => {
        if (ma.event == "added") {
            //console.log(ma.data);
        }
    };

  const sendMessage = message => {
    if (message) {
        
        const chatMessage = {'UserName': 'wei', 'update' : message, 'UserAvatar': 'https://firebasestorage.googleapis.com/v0/b/bcbe-f2a8e.appspot.com/o/images%2Fcoffee-500.jpg?alt=media&token=3603c7cc-14d2-4685-85ea-22fade3048cc', 'UserType' : 'circle-fan', 'SystemMessage' : 0, 'stickerImage': 0}
         
      pubnub
        .publish({ channel: channels[0], message: chatMessage })
        .then(() => setMessage(''));
    }
  };
    
    const fetchHistoryMessages = (status, response) => {
        setHistoryMessages(response.channels[props.channelId]);
        //console.log(response.channels[props.channelId]);
    }
    
    useEffect(() => {
        pubnub.addListener({ message: handleMessage, messageAction: handleMessageAction });
    }, []);
    
    useEffect(() => {
        const initTimeToken = 15343325004275466;
        setChannels([props.channelId]);
        setMetchMessageOptions({
            channels: [props.channelId],
            end: initTimeToken,
            includeMessageActions: true,
            count: 25
          });
    }, [props.channelId]);


  useEffect(() => {
      if (parseInt(props.channelId)) {
        pubnub.unsubscribeAll();
        pubnub.subscribe({ channels });
        pubnub.fetchMessages(fetchMessageOptions, fetchHistoryMessages);
      }
  }, [pubnub, channels]);

  return (
    <div style={pageStyles}>
      <div style={chatStyles}>
        <div style={headerStyles}>Chat</div>
        <div style={listStyles}>
          {messages.slice(0).reverse().map((message, index) => {
            return (
              <div key={`message-${index}`} style={messageStyles}>
                {message}
              </div>
            );
          })}
          {historyMessages && historyMessages.slice(0).reverse().map((event, index) => {
              if (event.message.stickerImage) {
                  return (
                      <div key={`message-${index}`} style={messageStyles}>
                      <img src={event.message.stickerImage} className="chatImage" />
                      </div>
                  );
              }
              return (
                  <div key={`message-${index}`} style={messageStyles}>
                      <img className="user-avatar" src={event.message.UserAvatar}/>{event.message.update}
                  </div>
              );
          })}
        </div>
        <div style={footerStyles}>
          <input
            type="text"
            style={inputStyles}
            placeholder="Type your message"
            value={message}
            onKeyPress={e => {
              if (e.key !== 'Enter') return;
              sendMessage(message);
            }}
            onChange={e => setMessage(e.target.value)}
          />
          <button
            style={buttonStyles}
            onClick={e => {
              e.preventDefault();
              sendMessage(message);
            }}
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
}

const pageStyles = {
  alignItems: 'center',
  background: '#282c34',
  display: 'flex',
  justifyContent: 'center',
};

const chatStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
};

const headerStyles = {
  background: '#323742',
  color: 'white',
  fontSize: '1.4rem',
  padding: '10px 15px',
};

const listStyles = {
  alignItems: 'flex-start',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  padding: '10px',
};

const messageStyles = {
  backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#333',
  fontSize: '1.1rem',
  margin: '5px',
  padding: '8px 15px',
};

const footerStyles = {
  display: 'flex',
};

const inputStyles = {
  flexGrow: 1,
  fontSize: '1.1rem',
  padding: '10px 15px',
};

const buttonStyles = {
  fontSize: '1.1rem',
  padding: '10px 15px',
};

export default Chat;
