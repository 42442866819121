import * as React from 'react';
import ChannelSensor from "./Sensor";
import Fab from "@material-ui/core/Fab";
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChannelDialog({ client }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab color="primary" aria-label="Sensors" style={{position: 'fixed', bottom: 80, right: 20}} onClick={handleClickOpen}>
        <EdgesensorHighIcon/>
      </Fab>
      <Dialog
          fullWidth
          maxWidth="md"
          sx={{
          "& .MuiDialog-paper": {
              margin: 0,
              width: "100%",
              'border-radius': 0,
              position: "absolute",
              bottom:0,
              height: "calc(100vh - 100vw * 9 / 16 - 56px)"
          }
          }}
          BackdropProps={{
            invisible: true
          }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Play with others"}
            <ExpandMoreOutlinedIcon onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 18,
              }}
            />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <ChannelSensor client={ client } />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
