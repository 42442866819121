import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA0nuR3aergYm3D4_PIaO55E-JW-hXJdUM",
  authDomain: "bcbe-f2a8e.firebaseapp.com",
  projectId: "bcbe-f2a8e",
  storageBucket: "bcbe-f2a8e.appspot.com",
  messagingSenderId: "465437923401",
  appId: "1:465437923401:web:5bb9a9f567caaf63ca8798"
};

/*
const firebaseConfig = {
  apiKey: "AIzaSyCLNp1XlKx1q2U-uFk4EPxEGjfphelECss",
  authDomain: "itdc-8ce1d.firebaseapp.com",
  databaseURL: "https://itdc-8ce1d-default-rtdb.firebaseio.com",
  projectId: "itdc-8ce1d",
  storageBucket: "itdc-8ce1d.appspot.com",
  messagingSenderId: "128691800835",
  appId: "1:128691800835:web:b1788c16c38062b5627736",
  measurementId: "G-1YXX8W6F4E"
};
*/
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const storageRef = ref(storage);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  storageRef,
};
