import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Slides.css";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";


const Slides2 = () => {
  return (
          <Swiper
              spaceBetween={30}
              effect={"fade"}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[EffectFade, Navigation, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="/img/2/1.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/2/2.jpg" />
              </SwiperSlide>
            </Swiper>
  );
};

export default Slides2

