import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, logout } from "../../firebase";
import { useMobileOrientation } from './Orientation';
import { useMobileMotion } from './Motion';
import "./Sensor.css";
import { useSpring, animated } from '@react-spring/web';

function Sensor() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const { orientation, requestAccess, revokeAccess, orientationError, accessGranted : orientationAccessGranted } = useMobileOrientation();
  const { motion, motions, requestMotionAccess, revokeMotionAccess, motionError, accessGranted : motionAccessGranted} = useMobileMotion();
  const navigate = useNavigate();

  const [cssTransformInverse, setCssTransformInverse] = useState({});
  const [cssTransformInverse1, setCssTransformInverse1] = useState({});
    
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
      
      const interval = setInterval(() => {
          // send data
          if ((currentOrientation.current !== null) && (currentMotion.current !== null)) {
              if (user) {
                  sendData();
              }
          }
      }, 15);// every 15ms
      
      const intervalRenderData = setInterval(() => {
            // receiv data
            renderData();
      }, 15);// every 15ms
      
      return () => {
          clearInterval(interval);
          clearInterval(intervalRenderData);
      }
      
  }, [user, loading]);

    const websocket = useRef(null);
    const currentMotion = useRef(null);
    const currentOrientation = useRef(null);
    const channelDataPacks = useRef([]);
    const receivedDataPacks = useRef([]);
    const debugTimer = useRef(null);
    
  useEffect(() => {
    var webSocketUri =  'wss://websocket.bcbe.ca/ws';
      if ( (orientationAccessGranted || motionAccessGranted) && (websocket.current === null) ) {
        websocket.current = new WebSocket(webSocketUri);
        websocket.current.onopen = function() {
          //console.log('Connected');
        };
        websocket.current.onclose = function() {
            //console.log('Closed');
        };
        websocket.current.onmessage = function(e) {
            if (debugTimer.current) {
                console.log('onmessage interval ',Date.now() - debugTimer.current);
            }
            debugTimer.current = Date.now();
            //console.log('e ', e);
            const dataReceived = JSON.parse(e.data);
            //console.log('dataReceived uuid message_type ', dataReceived.uuid, dataReceived.message_type);
            if (dataReceived.message_type === 'channel-data') {
                
                for (let i=0; i<dataReceived.message.data.length; i++) {
                    let userSensorExisting = false;
                    receivedDataPacks.current.forEach(function (uSensor, uIndex) {
                        if (uSensor.uuid === dataReceived.uuid) {
                            receivedDataPacks.current[uIndex].data.push(dataReceived.message.data[i]);
                            userSensorExisting = true;
                        }
                    });
                    
                    if (!userSensorExisting) {
                        const userSensor = {};
                        userSensor.uuid = dataReceived.uuid;
                        userSensor.data = [];
                        userSensor.data.push(dataReceived.message.data[i]);
                        receivedDataPacks.current.push(userSensor);
                        //console.log('userSensor, receivedDataPacks.current', userSensor, receivedDataPacks.current);
                    }
                    
                }
            }
            //console.log('receivedDataPacks.current ', receivedDataPacks.current);
            //console.log('onmessage end ',Date.now());
        };
        websocket.current.onerror = function(e) {
          console.log(e);
        };
      }
      
      if (orientationAccessGranted || motionAccessGranted) {
          if (!motionAccessGranted) requestMotionAccess();
          if (!orientationAccessGranted) requestAccess();
      }
      
      //console.log('orientationAccessGranted ', orientationAccessGranted, 'motionAccessGranted ', motionAccessGranted, 'websocket.current ', websocket.current);
     /*
    //clean up function
    return () => {
        console.log('executed...websocket.current close');
        if (websocket.current) {
            websocket.current.close();
        }
    }*/
  }, [orientationAccessGranted, motionAccessGranted]);
    
    useEffect(() => {
      currentMotion.current = motion;
      currentOrientation.current = orientation;
    }, [orientation, motion]);
    
    const sendData = () => {
        //collect 30 packs and send
        let messageJSON = {
             data: {orientation: currentOrientation.current, motion: currentMotion.current}
        };
        
        channelDataPacks.current.push(messageJSON);
        
        if (channelDataPacks.current.length === 30) { // 450ms
            //console.log('websocket ', websocket.current);
            if (websocket.current && (websocket.current.readyState === 1)) {
                const dataToSend = {}; // Object
                dataToSend.data = [];
                dataToSend.channel = '678';
                dataToSend.uuid =  user?.uid;
                dataToSend.msg = 'hello bcbe.ca';
                for (let i=0; i<30; i++) {
                    dataToSend.data.push(channelDataPacks.current[i]);
                }
                websocket.current.send(JSON.stringify(dataToSend));
                //console.log('Sent ', JSON.stringify(dataToSend))
            }
            channelDataPacks.current.length = 0;
        }
        
    }
    
    const renderData = () => {
        if (receivedDataPacks.current.length > 0) {
            receivedDataPacks.current.forEach(function (uSensor, uIndex) {
                
                const userID = receivedDataPacks.current[uIndex].uuid;
                if (receivedDataPacks.current[uIndex].data.length > 0) {
                    const firstSensorData = receivedDataPacks.current[uIndex].data.shift();
                    const angles = firstSensorData.data.orientation;
                    //console.log('angles ', angles);
                    if (angles
                          && typeof angles.alpha === 'number'
                          && typeof angles.beta === 'number'
                          && typeof angles.gamma === 'number'
                    ) {
                          const a = angles.alpha > 180 ? angles.alpha - 360 : angles.alpha;
                          const b = angles.beta - 90;
                          const g = angles.gamma > 180 ? 360 - angles.gamma : -angles.gamma;
                            if (uIndex === 0) {
                              setCssTransformInverse({
                                transform: `rotateX(${b}deg) rotateY(${g}deg) rotateZ(${a}deg)`,
                              });
                            } else {
                                setCssTransformInverse1({
                                  transform: `rotateX(${b}deg) rotateY(${g}deg) rotateZ(${a}deg)`,
                                });
                            }
                    }
                }
                
            });
        }
    }

  const orientationInfo = orientation && (
      <ul>
        <li><b>Orientation</b></li>
        <li>ɑ: <code>{orientation.alpha}</code></li>
        <li>β: <code>{orientation.beta}</code></li>
        <li>γ: <code>{orientation.gamma}</code></li>
      </ul>
  );

  const motionInfo = motion && (
      <ul>
        <li><b>Motion</b></li>
        <li><b><i>interval</i></b></li>
        <li>interval: <code>{motion.interval}</code></li>
        <li><b><i>acceleration</i></b></li>
        <li>x: <code>{motion.acceleration.x}</code></li>
        <li>y: <code>{motion.acceleration.y}</code></li>
        <li>z: <code>{motion.acceleration.z}</code></li>
        <li><b><i>accelerationIncludingGravity</i></b></li>
        <li>x: <code>{motion.accelerationIncludingGravity.x}</code></li>
        <li>y: <code>{motion.accelerationIncludingGravity.y}</code></li>
        <li>z: <code>{motion.accelerationIncludingGravity.z}</code></li>
        <li><b><i>rotationRate</i></b></li>
        <li>ɑ: <code>{motion.rotationRate.alpha}</code></li>
        <li>β: <code>{motion.rotationRate.beta}</code></li>
        <li>γ: <code>{motion.rotationRate.gamma}</code></li>
      </ul>
  );
    
    const getDistance = () => {
        let dx=0.0;
        let vx=0.0;
        //console.log('motions ', motions);
        if (motions) {
            for (let i=1; i<motions.length; i++)
             {
                 if (motions[i-1] && motions[i]) {
                     if ((Math.abs(motions[i].acceleration.x) > 0.1)&&(Math.abs(motions[i-1].acceleration.x) > 0.1)) {
                       vx=(motions[i-1].acceleration.x + motions[i].acceleration.x)/2.0*15/1000;
                       dx+=vx*15/1000;
                     }
                 }
             }
        }
        setDistance(dx);
    }
    
    const [distance, setDistance] = useState(0.0);

  return (
    <div className="dashboard">
          <div class="flex-container">
            <div class="flex-item-left">
              <div className="gyro-cube-container">
                      <div id="c1" className="gyro-cube" style={cssTransformInverse}>
                        <div className="gyro-cube-side gyro-cube-front">1</div>
                        <div className="gyro-cube-side gyro-cube-back">2</div>
                        <div className="gyro-cube-side gyro-cube-left">3</div>
                        <div className="gyro-cube-side gyro-cube-right">4</div>
                        <div className="gyro-cube-side gyro-cube-top">5</div>
                        <div className="gyro-cube-side gyro-cube-bottom">6</div>
                      </div>
              </div>
            </div>
            <div class="flex-item-right">
              <div className="gyro-cube-container">
                      <div id="c2" className="gyro-cube" style={cssTransformInverse1}>
                        <div className="gyro-cube-side gyro-cube-front">A</div>
                        <div className="gyro-cube-side gyro-cube-back">B</div>
                        <div className="gyro-cube-side gyro-cube-left">C</div>
                        <div className="gyro-cube-side gyro-cube-right">D</div>
                        <div className="gyro-cube-side gyro-cube-top">E</div>
                        <div className="gyro-cube-side gyro-cube-bottom">F</div>
                      </div>
              </div>
            </div>
          </div>
          <button className="dashboard__btn" onClick={requestAccess}>
            Start Orientation
          </button>
          <button className="dashboard__btn" onClick={revokeAccess}>
            Stop Orientation
          </button>
          <button className="dashboard__btn" onClick={requestMotionAccess}>
            Start Motion
          </button>
          <button className="dashboard__btn" onClick={revokeMotionAccess}>
            Stop Motion
          </button>
          {/*
          <button className="dashboard__btn" onClick={() => getDistance()}>
            Dance
          </button>
          <div>{distance}</div>
          */}
          <div>
          {orientationInfo}
          </div>
          <div>
          {motionInfo}
          </div>
    </div>
  );
}

export default Sensor;

