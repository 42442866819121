import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useSpring, animated } from '@react-spring/web';

export type DeviceMotion = {
    acceleration: {
      x: number | null,
      y: number | null,
      z: number | null
    },
    accelerationIncludingGravity: {
      x: number | null,
      y: number | null,
      z: number | null
    },
    rotationRate: {
      alpha: number | null,
      beta: number | null,
      gamma: number | null
    },
    interval: number | null
}

type UseDeviceMotionData = {
  motion: DeviceMotion | null,
  error: Error | null,
  // The requestAccess() could only be called on a user gesture (e.g. on click).
  requestAccess: () => Promise<boolean>,
  revokeAccess: () => Promise<void>,
};

const roundAngle = (angle: number | null): number | null => {
  if (typeof angle !== 'number') {
    return angle;
  }
  const fractionDigits = 2;
  return +angle.toFixed(fractionDigits);
};

export const useMobileMotion = (): UseDeviceMotionData => {
  const [motionError, setMotionError] = useState(null);
  const [motion, setMotion] = useState(null);
  const [motions, setMotions] = useState(null);
  const [accessGranted, setAccessGranted] = useState(false);
    
  let mx = 0;
  let my = 0;
  let mA = 0;
  let mB = 0;
    
  const motionsArray = [];

  const onDeviceMotion = (event: DeviceMotionEvent): void => {
    const motions: DeviceMotion = {
        acceleration: {
          x: roundAngle(event.acceleration.x),
          y: roundAngle(event.acceleration.y),
          z: roundAngle(event.acceleration.z),
        },
        accelerationIncludingGravity: {
          x: roundAngle(event.accelerationIncludingGravity.x),
          y: roundAngle(event.accelerationIncludingGravity.y),
          z: roundAngle(event.accelerationIncludingGravity.z),
        },
        rotationRate: {
            alpha: roundAngle(event.rotationRate.alpha),
            beta: roundAngle(event.rotationRate.beta),
            gamma: roundAngle(event.rotationRate.gamma),
        },
        interval: event.interval,
    };
    setMotion(motions);
    //currentMotion = motions;
  };

  const revokeAccessAsync = async (): Promise<void> => {
    window.removeEventListener('devicemotion', onDeviceMotion);
    setMotion(null);
  };

  const requestAccessAsync = async (): Promise<boolean> => {
    if (!DeviceMotionEvent) {
      setMotionError(new Error('Device motion event is not supported by your browser'));
      return false;
    }

    // Requesting the permission to access device orientation in iOS.
    if (
      // @ts-ignore
      DeviceMotionEvent.requestPermission
      // @ts-ignore
      && typeof DeviceMotionEvent.requestPermission === 'function'
    ) {
      let permission: PermissionState;
      try {
        // @ts-ignore
        permission = await DeviceMotionEvent.requestPermission();
      } catch (err) {
        // @ts-ignore
        const e = new Error((err && err.message) || 'unknown error');
        setMotionError(e);
        return false;
      }
      if (permission !== 'granted') {
        setMotionError(new Error('Request to access the device orientation was rejected'));
        return false;
      }
    }

    window.addEventListener('devicemotion', onDeviceMotion);
    setAccessGranted(true);

    return true;
  };

  const requestMotionAccess = useCallback(requestAccessAsync, []);
  const revokeMotionAccess = useCallback(revokeAccessAsync, []);

  useEffect(() => {
    return (): void => {
      revokeMotionAccess();
    };
  }, [revokeMotionAccess]);

  return {
    motion,
    motions,
    motionError,
    requestMotionAccess,
    revokeMotionAccess,
    accessGranted,
  };
};

