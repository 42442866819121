import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';

// https://github.com/wojtekmaj/react-calendar
import 'react-calendar/dist/Calendar.css';
import "./Schedule.css";

// https://fullcalendar.io/docs/react
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

const Schedule = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [value, onChange] = useState(new Date());

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    
    function DesktopView() {
        return (
            <div className="schedule">
                <FullCalendar
                  plugins={[ dayGridPlugin ]}
                  initialView="dayGridMonth"
                  weekends={true}
                  events={[
                      { title: 'monad.social Schedule', date: '2022-06-15' },
                      { title: 'monad.social open circle', date: '2022-06-16' },
                      { title: 'DJ monad', date: '2022-06-20' }
                  ]}
                />

            </div>
        );
    }

    function MobileView() {
        return (
            <div className="schedule">
                <Calendar onChange={onChange} value={value} />
            </div>
        );
    }
    
    if (isMobile) {
        return <MobileView />;
      }
      return <DesktopView />;
}

export default Schedule
