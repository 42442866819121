import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Channel.css";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import ChannelsList from "./ChannelsList"

function Channel() {
  return (
    <div className="channel-item">
      <div className="channel__container">
          <ChannelsList />
      </div>
    </div>
  );
}

export default Channel;


