import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, logout } from "../../firebase";


import "./GRPCChat.css";
import Header from "./components/Header";

import { User } from "./chat_pb";
import { ChatServiceClient } from "./chat_grpc_web_pb";
import ChatPage from "./pages/ChatPage";

export const client = new ChatServiceClient(
  "https://grpcproxy.monad.social",
  null,
  null
);

function GRPCChat() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);
    
    const inputRef = useRef(null);
    const [submitted, setSubmitted] = useState(null);
    function joinHandler() {
      const _username = inputRef.current.value;
        
      const obj = {name: _username, age: 30, city: "New York"};
      const myJSON = JSON.stringify(obj);

      const user = new User();
      user.setId(Date.now());
      user.setName(myJSON);

      client.join(user, null, (err, response) => {
        if (err) return console.log(err);
        const error = response.getError();
        const msg = response.getMsg();

        if (error === 1) {
          console.log(error, msg);
          setSubmitted(true);
          //window.alert("Username already exists.");
          return;
        }
        window.localStorage.setItem("username", _username.toString());
        //window.localStorage.setItem("username", myJSON);
        setSubmitted(true);
        // history.push("chatslist");
      });
    }

    function renderChatPage() {
      return <ChatPage client={client} />;
    }

    function renderJoinPage() {
      return (
        <div>
          <div>
            <h1>Join Channel...</h1>
          </div>
          <div style={{ padding: "10px 0" }}>
            <input
              style={{ fontSize: "1.3rem" }}
              type="text"
              ref={inputRef}
              placeholder="Your username..."
            />
          </div>
          <div>
            <button
              onClick={joinHandler}
              style={{
                padding: "7px 38px",
                fontSize: "1.2em",
                boxSizing: "content-box",
                borderRadius: "4px",
              }}
            >
              Join
            </button>
          </div>
        </div>
      );
    }

  return (
    <div className="dashboard">
      <div className="dashboard__container">
        hi
        <div>{name}</div>
        <div>{user?.uid}</div>
        <button className="dashboard__btn" onClick={logout}>
          Logout
        </button>
        <div>
          <Header />
          <main className="main">
            {submitted ? renderChatPage() : renderJoinPage()}
          </main>
        </div>
      </div>
    </div>
  );
}

export default GRPCChat;
