import React from "react";
import PropTypes from "prop-types";
import "./VimeoPlayer.css";

const VimeoPlayer = ({ embedId }) => (
    <div className="bcbe-player">
      <div className='page-section-wrapper'>
        <div className='row'>
          <div className='double-column'>
            <div className='video-player-column'>
                <div className="vimeo-player-wrap"><iframe src={`https://player.vimeo.com/video/${embedId}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className="vimeo-player-frame"></iframe></div>
            </div>
          </div>
          <div className='column hide-on-mobile'>
            <div className='player-message-container'>
                Welcome to BCBE
            </div>
          </div>
        </div>
      </div>
    </div>
);

VimeoPlayer.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default VimeoPlayer;
