import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './Login';
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import VimeoPlayer from "./VimeoPlayer"
import "./App.css";
//import Nav from './Theme/Nav';
import Navbar from './Theme/Navbar';
import Profile from './Content/Profile/Profile';
import Sensor from './Content/Sensor/Sensor';
import Circle from './Content/Circle/Circle';
import Channel from './Content/Channel/Channel';
import ChannelDetail from './Content/Channel/ChannelDetail';
import GRPCChat from './Content/GRPCChat/GRPCChat';
import CreateCircle from './Content/Circle/CreateCircle';
import CircleDetail from './Content/Circle/CircleDetail';
import Schedule from './Content/Schedule/Schedule';
import NearBlockchain from './Content/Blockchain/Near';

class App extends Component {
    render() {
        return (
            <div className="page-content">
                <VimeoPlayer embedId="107995891" />
                <Router>
                    <Navbar />
                    <div className="container">
                        <Routes>
                          <Route exact path="/" element={<Login />} />
                          <Route exact path="/register" element={<Register />} />
                          <Route exact path="/reset" element={<Reset />} />
                          <Route exact path="/circle" element={<Circle />} />
                          <Route exact path="/circle/:circleSlug" element={<CircleDetail />} />
                          <Route exact path="/circle/create" element={<CreateCircle />} />
                          <Route exact path="/schedule" element={<Schedule />} />
                          <Route exact path="/dashboard" element={<Dashboard />} />
                          <Route exact path="/profile" element={<Profile />} />
                          <Route exact path="/blockchain" element={<NearBlockchain />} />
                          <Route exact path="/sensor" element={<Sensor />} />
                          <Route exact path="/channel" element={<Channel />} />
                          <Route exact path="/channel/:channelSlug" element={<ChannelDetail />} />
                          <Route exact path="/chat" element={<GRPCChat />} />
                        </Routes>
                    </div>
                </Router>
            </div>
        )
    }
}

export default App
