import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./CreateCircle.css";
import { auth, db, logout, storageRef } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { query, collection, getDocs, where } from "firebase/firestore";

import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

function CreateCircle() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
    
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
      
      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: 'image/png'
      };

      // Upload file and metadata to the object 'images/{file.name}'
      const imagesRef = ref(storageRef, 'images/' + file.name);
      const uploadTask = uploadBytesResumable(imagesRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
          });
        }
      );
      
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

  return (
    <div className="create_circle">
      <div className="create_circle__container">
          <Box sx={{
              width: '100%',
              height: '50vh',
            }}
          >
              <Stack spacing={2} direction="column">
                  <TextField
                    required
                    id="create-circle-name"
                    label="Name"
                    placeholder="Name"
                    variant="standard"
                  />
                  <TextField
                    id="create-circle-description"
                    label="Description"
                    placeholder="Description"
                    multiline
                  />
              </Stack>
          </Box>
          <Stack spacing={2} direction="row">
                <Tooltip
                  title="Create a circle"
                  placement="top"
                >
                    <Button variant="outlined">Create Circle</Button>
              </Tooltip>
          </Stack>
          <Stack spacing={2} direction="row">
                <Tooltip
                  title="Upload an Image"
                  placement="top"
                >
                  <Button
                    variant="contained"
                    component="label"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                    />
                  </Button>
              </Tooltip>
          </Stack>
      </div>
    </div>
  );
}

export default CreateCircle;


