import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';
import "./ChannelDetail.css";
import ResponsiveAppBar from "./ChannelNav";
import ChannelDialog from "./ChannelDialog";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Image } from 'mui-image';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import DirectionsIcon from '@mui/icons-material/Directions';

import { ChatMessage, ReceiveMsgRequest, Empty } from "../GRPCChat/chat_pb";
import { ChatServiceClient } from "../GRPCChat/chat_grpc_web_pb";
import ChatPage from "../GRPCChat/pages/ChatPage";

export const client = new ChatServiceClient(
  "https://grpcproxy.monad.social",
  null,
  null
);

function ChannelDetail() {
    let { channelSlug } = useParams();
    
    const [channel, setChannel] = useState({});
    const inputRef = React.useRef();

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            sendMessage();
        }
    };
    
    const sendMessage = () => {
        const message = inputRef.current.value;
        const username = "wei";
        const obj = {name: username, age: 30, city: "Vancouver"};
        const myJSON = JSON.stringify(obj);
        
        //chatContentObj
        const chatContentObj = {type: "chatText", value: message};
        //realtimeDataObj
        const realtimeDataObj = null;
        //Message Obj
        const messageObj = {chatContent: chatContentObj, realtimeData: realtimeDataObj};
        const messageObjJSON = JSON.stringify(messageObj); // as string
          
        const msg = new ChatMessage();
        //msg.setMsg(message);
        msg.setMsg(messageObjJSON);
        msg.setFrom(myJSON);
        msg.setTime(new Date().toLocaleString());

        client.sendMsg(msg, null, (err, response) => {
          //console.log(response);
        });
        
        inputRef.current.value = "";
    }

    const fetchCircle = async () => {
      try {
          const q = query(collection(db, "channels"), where("slug", "==", channelSlug));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
          setChannel(
                    {
                        id: data.id,
                        name: data.name,
                        image: data.image,
                        slug: data.slug,
                    }
                );
          
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };

  useEffect(() => {
      fetchCircle();
  }, []);

  return (
    <div className="channel_detail">
      <div className="channel_detail__container">
          <ResponsiveAppBar channel={channel} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}  display={{ xs: 'none', md: 'block' }} order={{ xs: 2, md: 1 }}>
                First column (twice width as the second column on desktop)
                <div><Image className="cover-image" sx={{ borderRadius: 2 }} src={channel.image} width={'100%'} /></div>
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <div className="chat-container">
                  <div className="chat-input-bar">
                      <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', 'border-radius': 0 }}
                      >
                        <InputBase
                          inputRef={inputRef}
                          onKeyPress={handleKeyPress}
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Message..."
                          inputProps={{ 'aria-label': 'Message...' }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '2px' }} aria-label="send">
                          <SendIcon onClick={sendMessage} />
                        </IconButton>
                      </Paper>
                  </div>
                  <div className="chat-content">
                    <ChatPage client={client} />
                  </div>
              </div>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <ChannelDialog client={client}/>
          </Box>
      </div>
    </div>
  );
}

export default ChannelDetail;




