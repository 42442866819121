import React, { useEffect, useState } from "react";
import Table from './Table';
import Form from './Form';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import Slides from "./Slides";
import Slides2 from "./Slides2";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
        
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

  return (
    <div className="dashboard">
          
      <div className='page-section-wrapper'>
          <div className='row'>
            <div className='column'>
              <div className="dashboard__container">
                Logged in as
                <div>{name}</div>
                <div>{user?.uid}</div>
                <button className="dashboard__btn" onClick={logout}>
                  Logout
                </button>
              </div>
            </div>
            <div className='column'>
              <div className="paypalCheckoutBox">
                  <PayPalScriptProvider options={{ "client-id": "ATsn1pYqpIQ3r-lgKZYzRbPGe_KSnaUWSPR4w341Td4jxEndM5QoALWdWkXX1hQMQ12ufY4dXdyXS8QK",components: "buttons",intent: "subscription",vault: true,}}>
                        <SubscribeButton type="subscription" />
                  </PayPalScriptProvider>
              </div>
            </div>
            <div className='column'>
                <MonadStripe />
            </div>
          </div>
          <div className='row'>
            <div className='double-column'>
              
            </div>
            <div className='column'>
              
            </div>
          </div>
          <div className='row'>
            <div className='column slider-fix'>
                <div className='slider-container'>
                    <Slides />
                </div>
            </div>
          <div className='column slider-fix'>
              <div className='slider-container'>
                  <Slides2 />
              </div>
          </div>
            <div className='column'>
              
            </div>
          </div>
      </div>
    </div>
  );
}

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");


function MonadStripe() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // https://stripe.com/docs/payments/quickstart
    // Create PaymentIntent as soon as the page loads
    // https://bcbe.ca/stripepaymentintent
    fetch("https://stripepaymentintent-hzsgsogqgq-uc.a.run.app/stripepaymentintent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="MonadStripe">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

const SubscribeButton = ({ type }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (<PayPalButtons
        createSubscription={(data, actions) => {
            return actions.subscription
                .create({
                    plan_id: "P-5RH3884898820971DMKT4JFA",
                })
                .then((orderId) => {
                    // Your code here after create the order
                    return orderId;
                });
        }}
        style={{
            label: "subscribe",
        }}
    />);
};

export default Dashboard;
