import React, { useEffect, useState, useRef } from "react";
import "./Sensor.css";
import { useMobileOrientation } from '../Sensor/Orientation';
import { useMobileMotion } from '../Sensor/Motion';
import { ChatMessage, ReceiveMsgRequest, Empty } from "../GRPCChat/chat_pb";

export default function ChannelSensor({ client }) {
  const { orientation, requestAccess, revokeAccess, orientationError, accessGranted : orientationAccessGranted } = useMobileOrientation();
  const { motion, motions, requestMotionAccess, revokeMotionAccess, motionError, accessGranted : motionAccessGranted} = useMobileMotion();
    
  const [cssTransformInverse, setCssTransformInverse] = useState({});
  const [cssTransformInverse1, setCssTransformInverse1] = useState({});
    
  const [msgList, setMsgList] = useState([]);
  const username = "wei";
  const currentMotion = useRef(null);
  const currentOrientation = useRef(null);
  const receivedDataPacks = useRef([]);
    
  //---
  var sensorStream = null;

  // Define a function to create a new stream
  function createStream() {
      const strRq = new ReceiveMsgRequest();
      strRq.setUser(username);

      sensorStream = client.receiveMsg(strRq, {});
      sensorStream.on("data", (response) => {
      //console.log("response ", response);
          
        let obj;
        try {
          obj = JSON.parse(response.getFrom());
            //console.log('obj',obj);
        } catch (e) {}
        const from = obj?.name;
        
        try {
            obj = JSON.parse(response.getMsg());
            //console.log('obj',obj);
        } catch (e) {}
        const messageType = obj?.realtimeData?.type;
        if (messageType == "sensorData") {
            const data = obj?.realtimeData?.value.data;
            const time = response.getTime();

            receivedDataPacks.current.push(data);
            
            //console.log("real time data, Motion: " + data.motion + " Orientation: " + data.orientation, " from:" + from);
        }
      });

      sensorStream.on("status", function (status) {
        //console.log(status.code, status.details, status.metadata);
          if (status.code == 2) {
              //proxy time out
              // Cancel the current stream
              cancelStream(sensorStream);
              // Create a new stream
              createStream();
          }
      });

      sensorStream.on("end", () => {
        console.log("Stream ended.");
        // reconnect to the server
        cancelStream(sensorStream);
        // Create a new stream
        createStream();
      });
      
      // Listen for error events
      sensorStream.on('error', function(error) {
        // Do something with the error object
        //console.error(error.code);
        //console.error(error.message);
        //console.error(error.metadata);
        // reconnect to the server
        cancelStream(sensorStream);
        // Create a new stream
        createStream();
      });
      
      console.log("new stream created.");
  }

  // Define a function to cancel the stream
  function cancelStream(stream) {
      // Check if the stream is valid
      if (stream) {
          // Cancel the stream
          stream.cancel();
      }
  }
  //---
    
  useEffect(() => {
        // Create the initial stream
        createStream();
        
        // recreate a stream when get stream end status from proxy
        // proxy connect time out will send stream end code
        const interval = setInterval(() => {
            // Cancel the current stream
            cancelStream(sensorStream);
            // Create a new stream
            createStream();
        }, 1800000); // 30mins
      
      
        const intervalSendData = setInterval(() => {
          // send data
          //if ((currentOrientation.current !== null) && (currentMotion.current !== null)) {
          //console.log("currentOrientation ", currentOrientation);
          if (currentOrientation.current !== null) {
                  sendSensorData();
          }
        }, 15);// every 15ms
      
      
        const intervalRenderData = setInterval(() => {
            // receiv data
            renderData();
        }, 15);// every 15ms
        
        return () => {
            clearInterval(interval);
            clearInterval(intervalSendData);
            clearInterval(intervalRenderData);
        }
  }, []);
    
  useEffect(() => {
      currentMotion.current = motion;
      currentOrientation.current = orientation;
  }, [orientation, motion]);
    
  const sendSensorData = () => {
    const messageJSON = {
           data: {orientation: currentOrientation.current, motion: currentMotion.current}
    };
    
    const username = "wei";
    const obj = {name: username, age: 30, city: "Vancouver"};
    const myJSON = JSON.stringify(obj);
    
    //chatContentObj
    const chatContentObj = null;
    //realtimeDataObj
    const realtimeDataObj =  {type: "sensorData", value: messageJSON};
    //console.log("realtimeDataObj ",realtimeDataObj);
      
    //Message Obj
    const messageObj = {chatContent: chatContentObj, realtimeData: realtimeDataObj};
    const messageObjJSON = JSON.stringify(messageObj); // as string
      
    const msg = new ChatMessage();
    //msg.setMsg(message);
    msg.setMsg(messageObjJSON);
    msg.setFrom(myJSON);
    msg.setTime(new Date().toLocaleString());

    client.sendMsg(msg, null, (err, response) => {
      //console.log(response);
    });
    
  }
    
  const renderData = () => {
    if (receivedDataPacks.current.length > 0) {
        const firstSensorData = receivedDataPacks.current.shift();
        //console.log('firstSensorData ', firstSensorData);
        const angles = firstSensorData.orientation;
        console.log('angles ', angles);
        if (angles
              && typeof angles.alpha === 'number'
              && typeof angles.beta === 'number'
              && typeof angles.gamma === 'number'
        ) {
              const a = angles.alpha > 180 ? angles.alpha - 360 : angles.alpha;
              const b = angles.beta - 90;
              const g = angles.gamma > 180 ? 360 - angles.gamma : -angles.gamma;
                //if (uIndex === 0) {
                  setCssTransformInverse({
                    transform: `rotateX(${b}deg) rotateY(${g}deg) rotateZ(${a}deg)`,
                  });
                //} else {
                    //setCssTransformInverse1({
                    //transform: `rotateX(${b}deg) rotateY(${g}deg) rotateZ(${a}deg)`,
                    //});
                //}
        }
    }
  }
    
  const orientationInfo = orientation && (
        <ul>
          <li><b>Orientation</b></li>
          <li>ɑ: <code>{orientation.alpha}</code></li>
          <li>β: <code>{orientation.beta}</code></li>
          <li>γ: <code>{orientation.gamma}</code></li>
        </ul>
  );

  const motionInfo = motion && (
        <ul>
          <li><b>Motion</b></li>
          <li><b><i>interval</i></b></li>
          <li>interval: <code>{motion.interval}</code></li>
          <li><b><i>acceleration</i></b></li>
          <li>x: <code>{motion.acceleration.x}</code></li>
          <li>y: <code>{motion.acceleration.y}</code></li>
          <li>z: <code>{motion.acceleration.z}</code></li>
          <li><b><i>accelerationIncludingGravity</i></b></li>
          <li>x: <code>{motion.accelerationIncludingGravity.x}</code></li>
          <li>y: <code>{motion.accelerationIncludingGravity.y}</code></li>
          <li>z: <code>{motion.accelerationIncludingGravity.z}</code></li>
          <li><b><i>rotationRate</i></b></li>
          <li>ɑ: <code>{motion.rotationRate.alpha}</code></li>
          <li>β: <code>{motion.rotationRate.beta}</code></li>
          <li>γ: <code>{motion.rotationRate.gamma}</code></li>
        </ul>
  );
    
  return (
    <div>
      <div class="channel-flex-container">
        <div class="flex-item-left">
          <div className="gyro-cube-container">
                  <div id="c1" className="gyro-cube" style={cssTransformInverse}>
                    <div className="gyro-cube-side gyro-cube-front">1</div>
                    <div className="gyro-cube-side gyro-cube-back">2</div>
                    <div className="gyro-cube-side gyro-cube-left">3</div>
                    <div className="gyro-cube-side gyro-cube-right">4</div>
                    <div className="gyro-cube-side gyro-cube-top">5</div>
                    <div className="gyro-cube-side gyro-cube-bottom">6</div>
                  </div>
          </div>
        </div>
        <div class="flex-item-right">
          <div className="gyro-cube-container">
                  <div id="c2" className="gyro-cube" style={cssTransformInverse1}>
                    <div className="gyro-cube-side gyro-cube-front">A</div>
                    <div className="gyro-cube-side gyro-cube-back">B</div>
                    <div className="gyro-cube-side gyro-cube-left">C</div>
                    <div className="gyro-cube-side gyro-cube-right">D</div>
                    <div className="gyro-cube-side gyro-cube-top">E</div>
                    <div className="gyro-cube-side gyro-cube-bottom">F</div>
                  </div>
          </div>
        </div>
      </div>
      <button className="dashboard__btn" onClick={sendSensorData}>
            Start Streaming
      </button>
      <button className="dashboard__btn" onClick={requestAccess}>
        Start Orientation
      </button>
      <button className="dashboard__btn" onClick={revokeAccess}>
        Stop Orientation
      </button>
      <button className="dashboard__btn" onClick={requestMotionAccess}>
        Start Motion
      </button>
      <button className="dashboard__btn" onClick={revokeMotionAccess}>
        Stop Motion
      </button>
      <div>
      {orientationInfo}
      </div>
      <div>
      {motionInfo}
      </div>
    </div>
  );
}
