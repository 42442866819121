import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';
import "./CircleDetail.css";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import Chat from "../Chat/Chat"

const pubnub = new PubNub({
  publishKey: 'pub-c-12c07277-68b3-49c7-9df6-b04c2533fabd',
  subscribeKey: 'sub-c-7d3add7c-430a-4133-8503-4aaeaa589680',
  uuid: '1'
});

function CircleDetail() {
    let { circleSlug } = useParams();
    
    const [circle, setCircle] = useState({});

    const fetchCircle = async () => {
      try {
          const q = query(collection(db, "circles"), where("slug", "==", circleSlug));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
          setCircle(
                    {
                        id: data.id,
                        name: data.name,
                        image: data.image,
                        slug: data.slug,
                    }
                );
          
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };

  useEffect(() => {
      fetchCircle();
  }, []);

  return (
    <div className="circle_detail">
      <div className="circle_detail__container">
          <div className="circle_name">{circle.name}</div>
          <div><img className="circle_image" src={circle.image} /></div>
          <PubNubProvider client={pubnub}>
            <Chat channelId={`${circle.id}`} />
          </PubNubProvider>
      </div>
    </div>
  );
}

export default CircleDetail;



