import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db, logout, storageRef } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { query, collection, getDocs, where } from "firebase/firestore";
import "./ChannelsList.css";
import { Image } from 'mui-image';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function ChannelsList() {
    const [channels, setChannels] = useState([]);

    const fetchChannels = async () => {
      try {
        const q = query(collection(db, "channels"));
        const doc = await getDocs(q);
        
          for (var i=0; i<doc.docs.length; i++) {
              const data = doc.docs[i].data();
              setChannels((prevChannels) => [
                  ...prevChannels,
                  {
                      name: data.name,
                      image: data.image,
                      slug: data.slug,
                  },
              ]);
          }
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };

  useEffect(() => {
      fetchChannels();
  }, []);

  return (
    <div className="channelslist">
      <div className="channelslist__container">
          <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
              {channels.map((channel, index) => (
                 <Grid item xs={12} md={3} key={index}>
                     <Paper sx={{ width: '100%', paddingTop: 1, paddingLeft: 0.5, paddingRight: 0.5, paddingBottom: 1 }} elevation={10}>
                         <Typography variant="h6" component="div" sx={{ fontSize: 14}}>
                             <Link to={`/channel/${channel.slug}`}>
                                <div>{channel.name}</div>
                                <div><Image className="cover-image" sx={{ borderRadius: 2 }} src={channel.image} width={'100%'} /></div>
                             </Link>
                         </Typography>
                     </Paper>
                 </Grid>
              ))}
              </Grid>
          </Box>
      </div>
    </div>
  );
}

export default ChannelsList;
