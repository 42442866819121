import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase";
import * as nearAPI from "near-api-js";
import { parseSeedPhrase, generateSeedPhrase } from "near-seed-phrase";
import { v1 as uuidv1 } from 'uuid';

import "./Near.css";

// creates keyStore using private key in local storage
const { connect, keyStores, WalletConnection, utils } = nearAPI;

// to create a seed phrase with its corresponding Keys
const {seedPhrase, publicKey, secretKey} = generateSeedPhrase()

// To recover keys from the seed phrase
//const { publicKey, secretKey } = parseSeedPhrase(seedPhrase);

function NearBlockchain() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [nearname, setNearname] = useState("");
  const [nearAccountIsCreated, setNearAccountIsCreated] = useState("");
  const [walletConnection, setWalletConnection] = useState(null);
  const [nearIsSignedIn, setNearIsSignedIn] = useState(false);

  const connectNEAR = async () => {
      const connectionConfig = {
        networkId: "testnet",
        keyStore: new keyStores.BrowserLocalStorageKeyStore(),
        nodeUrl: "https://rpc.testnet.near.org",
        walletUrl: "https://wallet.testnet.near.org",
        helperUrl: "https://helper.testnet.near.org",
        explorerUrl: "https://explorer.testnet.near.org",
      };

      // connect to NEAR
      const nearConnection = await connect(connectionConfig);

      // create wallet connection
      const wallet = new WalletConnection(nearConnection);
      setWalletConnection(wallet);
      
      const isSignedIn = wallet.isSignedIn();
      setNearIsSignedIn(isSignedIn);
      
    try {
        if (isSignedIn) {
            // user is signed in
            const walletAccountId = wallet.getAccountId();
            setNearname(walletAccountId);
        } else {
            setNearname("Log in NEAR network");
        }
    } catch (err) {
      console.error(err);
      alert("An error occured while connecting to NEAR network");
    }
  };
    
  const loginNEAR = () => {
      walletConnection.requestSignIn(
        "bcbe.testnet", // contract requesting access
        "BCBE dApp", // optional title
        "https://bcbe.ca", // optional redirect URL on success
        "https://bcbe.ca" // optional redirect URL on failure
      );
  }
    
  const logoutNEAR = () => {
      walletConnection.signOut();
      setNearIsSignedIn(false);
  }
    
  const createNEARAccount = async () => {
      // create a new account using funds from the account used to create it.
      const { secretKey } = parseSeedPhrase("nation escape coffee bulk bright grass feature swallow coast rescue escape total");
      //console.log('master secret key:', secretKey);
      const keyPair = utils.KeyPair.fromString(secretKey);
      const keyStore = new nearAPI.keyStores.InMemoryKeyStore();
      keyStore.setKey("testnet", "bcbe.testnet", keyPair);

      const config = {
        keyStore, // instance of InMemoryKeyStore
        networkId: 'testnet',
        nodeUrl: 'https://rpc.testnet.near.org',
        walletUrl: 'https://wallet.testnet.near.org',
        helperUrl: 'https://helper.testnet.near.org',
        explorerUrl: 'https://explorer.testnet.near.org'
      };

      //console.log('sub account public key:', publicKey);
      
      // inside an async function
      const near = await connect(config);
      const nearUserName = uuidv1();
      //console.log('user name:', nearUserName);
      
      const account = await near.account("bcbe.testnet");
      const ret = await account.createAccount(
        nearUserName+".bcbe.testnet", // new account name
        publicKey, // public key for new account
        "100000000000000000000000" // initial balance for new account in yoctoNEAR, $NEAR 0.1
      );
      
      if (ret.transaction_outcome.outcome.status.SuccessReceiptId) {
          setNearAccountIsCreated(ret.transaction_outcome.outcome.status.SuccessReceiptId);
          //console.log('Create Account Result:', ret);
      }
      
      /*
      const ret = await walletAccountObj.createAccount(
        "user1.bcbe.testnet", // new account name
        publicKey, // public key for new account
        "1000000000000000000000000" // initial balance for new account in yoctoNEAR, $NEAR 1
      );
      console.log(ret);*/
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    connectNEAR();
      
  }, [user, loading]);

  return (
    <div className="dashboard">
      <div className="dashboard__container">
        <div><b>NEAR Account </b>
          {!nearAccountIsCreated ?
              <>
              {nearIsSignedIn ? nearname : (
                <div>
                    <button className="dashboard__btn" onClick={loginNEAR}>
                      Login NEAR Account
                    </button>
                    <button className="dashboard__btn" onClick={createNEARAccount}>
                      Create NEAR Sub Account
                    </button>
                </div>
                )
              }
              </> : <></>
          }
          </div>
          {nearAccountIsCreated && (
            <div>
              <div className="blockchain_longword">
                  <div><b>Seed Phrase</b></div>
                  <div>{seedPhrase}</div>
                  <div><b>Public Key</b></div>
                  <div>{publicKey}</div>
                  <div><b>Secret Key</b></div>
                  <div>{secretKey}</div>
                  <div><a href="https://wallet.testnet.near.org/" target="_blank">NEAR Wallet</a></div>
                  <button className="dashboard__btn" onClick={loginNEAR}>
                  Login NEAR Account
                  </button>
              </div>
            </div>
            )
          }
      </div>
          {nearIsSignedIn && (
                <button className="dashboard__btn" onClick={logoutNEAR}>
                  Logout NEAR Account
                </button>
            )
          }
    </div>
  );
}

export default NearBlockchain;

