import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db, logout, storageRef } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { query, collection, getDocs, where } from "firebase/firestore";
import "./CirclesList.css";

function CirclesList() {
    const [images, setImages] = useState([]);
    const [circles, setCircles] = useState([]);

    const fetchCircles = async () => {
      try {
        const q = query(collection(db, "circles"));
        const doc = await getDocs(q);
        
          for (var i=0; i<doc.docs.length; i++) {
              const data = doc.docs[i].data();
              setCircles((prevCircles) => [
                  ...prevCircles,
                  {
                      name: data.name,
                      image: data.image,
                      slug: data.slug,
                  },
              ]);
          }
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };
    
    const fetchImages = () => {
        // Points to 'images'
        const imagesRef = ref(storageRef, 'images');
        // Points to 'images/space.jpg'
        // Note that you can use variables to create child values
        const fileName = 'space.jpg';
        const spaceRef = ref(imagesRef, fileName);
        // File path is 'images/space.jpg'
        const path = spaceRef.fullPath;
        
        // Get the download URL
        getDownloadURL(spaceRef)
          .then((url) => {
            // Insert url into an <img> tag to "download"
              setImages(url);
          })
          .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          });
    };

  useEffect(() => {
      fetchCircles();
      fetchImages();
  }, []);

  return (
    <div className="circleslist">
      <div className="circleslist__container">
          {circles.map((circle, index) => (
              <div key={index}>
                   <Link to={`/circle/${circle.slug}`}>
                        <div>{circle.name}</div>
                        <div><img className="circle_thumbnail" src={circle.image} /></div>
                        <div><img className="circle_thumbnail" src={images} /></div>
                   </Link>
              </div>
          ))}
      </div>
    </div>
  );
}

export default CirclesList;
