import Chat from "./../../components/Chat";
import UsersList from "./../../components/UsersList";
import "./ChatPage.css";
import { ChatMessage, ReceiveMsgRequest, Empty } from "./../../chat_pb";
import { useEffect, useState } from "react";

export default function ChatPage({ client }) {
  const [users, setUsers] = useState([]);
  const [msgList, setMsgList] = useState([]);
  const username = window.localStorage.getItem("username");

  //---
    var chatStream = null;

    // Define a function to create a new stream
    function createStream() {
        const strRq = new ReceiveMsgRequest();
        strRq.setUser(username);

        chatStream = client.receiveMsg(strRq, {});
        chatStream.on("data", (response) => {
          //console.log("response ", response);
            
          let obj;
          try {
            obj = JSON.parse(response.getFrom());
              //console.log('obj',obj);
          } catch (e) {}
          const from = obj?.name;
          
          try {
              obj = JSON.parse(response.getMsg());
                //console.log('obj',obj);
          } catch (e) {}
          const messageType = obj?.chatContent?.type;
          if (messageType == "chatText") {
              const msg = obj?.chatContent?.value;
              const time = response.getTime();

              //console.log("sending friend msg:" + msg, " from:" + from);

              if (from === username) {
                setMsgList((oldArray) => [
                  ...oldArray,
                  { from, msg, time, mine: true },
                ]);
              } else {
                setMsgList((oldArray) => [...oldArray, { from, msg, time }]);
              }
          }
        });

        chatStream.on("status", function (status) {
          //console.log(status.code, status.details, status.metadata);
            if (status.code == 2) {
                //proxy time out
                // Cancel the current stream
                cancelStream(chatStream);
                // Create a new stream
                createStream();
            }
        });

        chatStream.on("end", () => {
          console.log("Stream ended.");
          // reconnect to the server
          cancelStream(chatStream);
          // Create a new stream
          createStream();
        });
        
        // Listen for error events
        chatStream.on('error', function(error) {
          // Do something with the error object
          //console.error(error.code);
          //console.error(error.message);
          //console.error(error.metadata);
          // reconnect to the server
          cancelStream(chatStream);
          // Create a new stream
          createStream();
        });
        
        console.log("new stream created.");
    }

    // Define a function to cancel the stream
    function cancelStream(stream) {
        // Check if the stream is valid
        if (stream) {
            // Cancel the stream
            stream.cancel();
        }
    }
  //---
    
  useEffect(() => {
      // Create the initial stream
      createStream();
      
      // recreate a stream when get stream end status from proxy
      // proxy connect time out will send stream end code
      const interval = setInterval(() => {
          // Cancel the current stream
          cancelStream(chatStream);
          // Create a new stream
          createStream();
      }, 1800000); // 30mins
      
      return () => {
          clearInterval(interval);
      }
  }, []);

  useEffect(() => {
    getAllUsers();
  }, []);

  function getAllUsers() {
    client.getAllUsers(new Empty(), null, (err, response) => {
      let usersList = response?.getUsersList() || [];
      usersList = usersList
        .map((user) => {
          //console.log('get all user', user);
          let obj;
            try {
              obj = JSON.parse(user.array[1]);
              //console.log('get all obj', obj);
              return {
                id: user.array[0],
                name: obj?.name,
              };
            } catch (e) {}
          return {
            id: null,
            name: null,
          };
        })
        .filter((u) => u.name !== username);
      setUsers(usersList);
    });
  }

  function sendMessage(message) {
    const obj = {name: username, age: 30, city: "New York"};
    const myJSON = JSON.stringify(obj);
      
    const msg = new ChatMessage();
    msg.setMsg(message);
    msg.setFrom(myJSON);
    msg.setTime(new Date().toLocaleString());

    client.sendMsg(msg, null, (err, response) => {
      console.log(response);
    });
  }

  return (
          /*
    <div className="chatpage">
      <div className="userslist-section">
        <div
          style={{ paddingBottom: "4px", borderBottom: "1px solid darkgray" }}
        >
          <div>
            <button onClick={getAllUsers}>REFRESH</button>
          </div>
          <div>
            <span>
              Logged in as <b>{username}</b>
            </span>
          </div>
        </div>
        <UsersList users={users} />
      </div>
      <div className="chatpage-section">
        <Chat msgList={msgList} sendMessage={sendMessage} />
      </div>
    </div>
            */
          <div className="chatpage-section">
            <Chat msgList={msgList} sendMessage={sendMessage} />
          </div>
  );
}
